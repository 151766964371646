<template>
  <section-wrap>
    <template #title>
      <div class="d-flex">
        <h3 class="section-title mr-5">
          Reports
        </h3>
        <report-period-filter
          v-model="period"
        />
      </div>
    </template>

    <section-container>
      <template #header>
        <filters-bar
          member-filter
          :deleted="period === 3"
        />
        <activity-bar
          v-if="period !== 3"
          :sort-by="period === 0"
          :add-time="period === 0"
          :activity-chart="period === 2"
          activity-time="05:45:40"
          activity-percent="80%"
          activity-hours="234:48:12"
        />
      </template>
      <template #body>
        <router-view
          activity-time="05:45:40"
          activity-percent="80%"
        />
      </template>
    </section-container>
  </section-wrap>
</template>

<script>
import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';
import FiltersBar from '@/components/collections/FiltersBar/FiltersBar';
import ActivityBar from '@/components/collections/ActivityBar/ActivityBar';
import ReportPeriodFilter from '@/views/Report/ReportPeriodFilter';

export default {
  name: 'Report',
  components: {
    ReportPeriodFilter,
    ActivityBar,
    FiltersBar,
    SectionContainer,
    SectionWrap,
  },
  data() {
    return {
      period: 0,
    };
  },
  created() {
    switch (this.$route.name) {
      case 'Daily':
        this.period = 0;
        break;

      case 'Weekly':
        this.period = 1;
        break;

      case 'Chart':
        this.period = 2;
        break;

      case 'Deleted':
        this.period = 3;
        break;

      default:
        this.period = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

</style>
